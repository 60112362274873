<template>
  <div>
    <v-card>
      <v-card-text>
        <DateTimePicker
          :lock-date-from="true"
          :tooltip-date-to="$t('RateReportElements.DateToToolTip')"
          @getDateFrom="dateFrom = $event"
          @getDateTo="dateTo = $event"
        ></DateTimePicker>
        <br>
        <v-row
          v-if="!$checkRole('ADVERTISERS') && !$checkRole('ADVERTISERS_USERS') && !$checkRole('PROVIDERS')"
        >
          <v-col
            cols="6"
            xl="6"
            md="6"
            sm="12"
          >
            <v-autocomplete
              v-model="advertiserId"
              :items="advertisers"
              item-text="name"
              item-value="id"
              :label="$t('Advertiser')"
              :disabled="loadingFirst"
              clearable
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="1"
            xl="1"
            md="1"
            sm="1"
          >
            <v-progress-circular
              v-if="loadingFirst"
              class="mt-5"
              indeterminate
              color="primary"
              :width="2"
              :size="20"
            ></v-progress-circular>
          </v-col>
        </v-row>
        <br>
        <v-btn
          color="primary"
          @click="getDataFromDB"
        >
          {{ $t('BtnSearch') }}
        </v-btn>
      </v-card-text>
    </v-card>
    <br>
    <div>
      <v-data-table
        :headers="headers"
        :items="dataFromDb"
        item-key="name"
        class="elevation-1"
        :sort-by.sync="orderBy"
        :sort-desc.sync="sortDesc"
        :search="search"
        :loading="loading"
        :locale="$i18n.locale"
      >
        <template v-slot:top>
          <div class="row">
            <div class="col-8"></div>
            <div class="col-4">
              <v-text-field
                v-model="search"
                :label="$t('LblSearch')"
                class="mx-4"
              ></v-text-field>
            </div>
          </div>
        </template>
        <template v-slot:item.campaignDateStop="{item}">
          <span>{{ moment(item.campaignDateStop).format("YYYY-MM-DD") }}</span>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import qs from 'qs'
import DateTimePicker from '../Components/DateTimePicker.vue'

export default {
  components: {
    DateTimePicker,
  },
  data() {
    return {
      orderBy: 'advertiser',
      sortDesc: false,
      advertiserId: null,
      advertisers: [],
      loadingFirst: false,
      search: '',
      dataFromDb: [],
      dateFrom: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1,
        new Date().getHours(),
        new Date().getMinutes(),
        new Date().getSeconds(),
      )
        .toISOString()
        .substr(0, 10),
      dateTo: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 1,
        new Date().getHours(),
        new Date().getMinutes(),
        new Date().getSeconds(),
      )
        .toISOString()
        .substr(0, 10),
      loading: false,
    }
  },
  computed: {
    headers() {
      return [
        { text: this.$t('RateReportElements.Advertiser'), value: 'advertiser' },
        { text: this.$t('RateReportElements.CampaignName'), value: 'campaignName' },
        { text: this.$t('RateReportElements.UnitType'), value: 'unitType' },
        { text: this.$t('RateReportElements.Fact'), value: 'fact' },
        {
          text:
            this.dateTo != null
              ? this.dateTo
              : new Date(
                  new Date().getFullYear(),
                  new Date().getMonth(),
                  new Date().getDate() - 1,
                  new Date().getHours(),
                  new Date().getMinutes(),
                  new Date().getSeconds(),
                )
                  .toISOString()
                  .substr(0, 10),
          value: 'factOfTheLastDay',
        },
        { text: this.$t('RateReportElements.Plan'), value: 'plan' },
        { text: this.$t('RateReportElements.Prognosis'), value: 'prognosis' },
        { text: this.$t('RateReportElements.UnitsPerDayNeed'), value: 'unitsPerDayNeed' },
        {
          text: `${this.$t('RateReportElements.DaysLeft')}\n(${new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate() - 1,
            new Date().getHours(),
            new Date().getMinutes(),
            new Date().getSeconds(),
          )
            .toISOString()
            .substr(0, 10)})`,
          value: 'daysLeft',
        },
        { text: this.$t('RateReportElements.CampaignDateStop'), value: 'campaignDateStop' },
      ]
    },
  },
  mounted() {
    if (new Date().getDate() === 1) {
      this.dateFrom = new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 1,
        1,
        new Date().getHours(),
        new Date().getMinutes(),
        new Date().getSeconds(),
      )
        .toISOString()
        .substr(0, 10)
    }
    this.firstLoad()
    this.$eventHub.$emit('setDateFrom', this.dateFrom)
    this.$eventHub.$emit('setDateTo', this.dateTo)
  },
  methods: {
    firstLoad() {
      this.getAdvertisers().then(() => {
        this.getDataFromDB()
      })
    },

    getAdvertisers() {
      this.loadingFirst = true

      return this.$http
        .get(`${this.$apiBaseURL}/publisher/filter/adv-users`, { params: { userId: this.$getUserId() } })
        .then(response => {
          this.advertisers = response.data

          // if (!this.advertiserId) {
          //   if (this.advertisers.length > 0) {
          //     this.advertiserId = this.advertisers[0].id
          //   }
          // }
          this.loadingFirst = false
        })
    },

    getDataFromDB() {
      this.loading = true

      const dateStart = this.dateFrom != null ? `${this.dateFrom}` : '1970-01-01'
      const dateFinish =
        this.dateTo != null
          ? `${this.dateTo}`
          : `${new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate() - 1,
              new Date().getHours(),
              new Date().getMinutes(),
              new Date().getSeconds(),
            )
              .toISOString()
              .substr(0, 10)}`

      const params = {
        dateTo: dateFinish,
        userId: this.$getUserId(),
      }

      if (this.advertiserId) {
        params.advertiserId = this.advertiserId
      }
      if (this.dateFrom != null) {
        params.dateFrom = dateStart
      }

      this.$http
        .get(`${Vue.prototype.$apiBaseURL}/rate/report?${qs.stringify(params, { arrayFormat: 'repeat' })}`)
        .then(response => {
          this.dataFromDb = response.data == null ? [] : response.data
          this.loading = false
        })
        .catch(error => {
          console.log(error)
          this.loading = false
        })
    },
  },
}
</script>
